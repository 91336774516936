import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
declare var io: any;
declare var Socket:any;
import { environment } from '@env'


@Injectable({ providedIn:'root'})

export class SocketService {

    socket: any ;
    componentLiten$ = new Subject<any>();
    pingTime: any;
    isSocketConnected:boolean= false;
    $getUnreadMessageCount = new Subject();
    $socketDIsconnectedEvent = new Subject<any>()
    constructor() { 
        
        //setTimeout(() => {
            this.OnSocketConnect();
        //}, 1000);
       
    }

    OnSocketConnect() {
        let socketUrl = environment.socketUrl;
        // if(socketUrl && !this.isSocketConnected){
            this.socket = io.connect(socketUrl, { secure: true,reconnection: true, dalley: 100});
    
            this.socket.on('connect', (message)=> {
                console.log("socket connected ",new Date())
                this.isSocketConnected = true;
                this.$getUnreadMessageCount.next(true);
                this.$socketDIsconnectedEvent.next(true);
            });
            this.socket.on('disconnect', (message)=> {
                console.log("socket is disconnected ",new Date())
                this.isSocketConnected = false;
                this.$getUnreadMessageCount.next(true);
                this.$socketDIsconnectedEvent.next(true);
                
            })

        //}
    }

    pingTimeDistory() {
        // clearInterval(this.pingTime); 
    }


    changeProviderStatus(data) {
        this.socket.emit('ChangeProviderStatus', { Status: data.status, UserId: data.UserId, ActionTime: data.ActionTime });
    }

    updateTelemedicineStatus(data) {
        this.socket.emit('UpdateStatus', { Status: data.Status, appointmentid: data.appointmentid });
    }

    disconnect() {
        this.socket.disconnect()
    }

    emitSocketEvent(event, data) {
        this.socket.emit(event, data)
    }

    public sendSMS = (data)=>{
        this.socket.emit(data.topic, data.message);
    }




}





