export const cacheUrls = [


// 'api/Provider/GetProviderList',             //master data            // this is causing error needs to be checked
// '/api/AdminCommon/GetMaritalStatus',        //master data
// 'api/AdminSurvey/GetReputationOverview',    // Used on review screen
// 'api/AdminSurvey/GetSurveyStatement',       // Used on review screen
// 'api/AdminSurvey/GetReviews',               // Used on review screen
// 'api/AdminSurvey/GetReviewInBarGraph',      // Used on review screen
// 'api/AdminCommon/GetAllLocations',          // Used on review screen
// 'api/AdminCommon/GetMappedLocation',        // Used on review screen

//MASTER DATA AS OBSERVED ON APPOINTMENT DETAILS SCREEN
// 'api/AdminCommon/GetMaritalStatus',
// 'api/AdminCommon/GetStates',
// 'api/Insurance/SearchInsurancePackage',
// '/api/appointment/GetAppointmentTypesFromLocal/',       this is causing error , needs to be checked
// 'api/AdminCommon/GetPharmacy/'
];
export const apiCache={
    url:{timestamp:'',Data:''}
  
}