import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalService } from './services/data-service/global.service'
import { Router } from '@angular/router';
import {SocketService} from './services/data-service/socket.io';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InstantUCAdmin';
  constructor(public globalService: GlobalService, private router: Router, private socketService:SocketService,
    private cd: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {


    setInterval(() => {

      if ((this.router.url == '/Auth/forgot-password')
        || (this.router.url == '/Auth/reset-password')
        || (this.router.url == '/Auth/reset-password-fta')
        || (this.router.url == '/Auth')
        || (this.router.url=='/calender')
      ) { console.log('No need to redirection ') }
      else {
        let token = localStorage.getItem("access_token")
        if (!token) {
          this.router.navigateByUrl("/Auth")
        }
      }


    }, 5000)
  }

}
