import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
@Injectable({"providedIn":"root"})

export class GlobalService {

loader:boolean = false;  
days = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
]

featureList=[];
sheduledAppoitmentList:[]

appointmentData :any={
  Data:{
    lstAppointmentList:[],
    Tab:""
  }
};
Setting$ = new Subject<any>();

public messsageContactList:any=[];
messagePageNo = 1;
messageLoad$ = new Subject<any>();
updatePharmacy$ = new Subject<any>();
practiceSettingMenu:boolean = false;
selectedFilter = 0;
constructor() {
  
}

getContectList() :Observable<any> {
  return this.messsageContactList;
}


calculate_age(dob) { 
    if(dob){
      let date = new Date(dob)
      var diff_ms = Date.now() - date.getTime();
      var age_dt = new Date(diff_ms); 
    
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    }else{
      return null
    }
     
     
  }

 GoogleMapWithLatLng(location: any) {
    try {
        
        const imagePath = `https://www.google.com/maps?q=${location.lat},${location.lng}`;
        return imagePath;
    } catch (error) {
        return `https://www.google.com/maps?q=${0},${0}`;
    }
}

sundaytToSaturdayDaye(currentDate){
  let  curr = new Date(currentDate); // get current date
  let  first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  let last = first + 6; // last day is the first day + 6
  
  let firstday = new Date(curr.setDate(first)).toUTCString();
  let lastday = new Date(currentDate.setDate(last)).toUTCString();
  return {fromDate:firstday, toDate:lastday}
}

dateFormat(date){
  let newDate = new Date(date);
      let m:any = newDate.getMonth()+1;
      let d:any = newDate.getDate();
          d =  d<10?'0'+d:d;
          m = m<10?'0'+m:m;
      let y = newDate.getFullYear();
      return `${m}/${d}/${y}`

}

weekCalenderDate(currentDate){
  let weekDay =[];
  let  curr = new Date(currentDate); // get current date
  let  first = new Date(curr.setDate(curr.getDate() - currentDate.getDay() - 1)); // First day is the day of the month - the day of the week
   
    for(let i=0; i<7; i++){
    let newDate = first.setDate(first.getDate() + 1);
    weekDay.push({date: this.dateFormat(newDate),formatDate: this.dayMonthFormat(newDate)});
  }
  

  return weekDay;
}

singleStringDate(date){
  let newDate = {
    formetedDate: this.dayMonthFormat(date),
    date:date
  }
  return newDate;
}

dayMonthFormat(date){
  let newDate = new Date(date);
  let day = this.days[newDate.getDay()];
  let date1:any = newDate.getDate();
   date1 = date1<10?'0'+date1:date1;
  let month:any = newDate.getMonth()+1;
  month =  month<10?'0'+month:month;
  return `${day} ${month}/${date1}`
}

onlyDayAndMonthFormat(date){
  let newDate = new Date(date);
  let day = this.days[newDate.getDay()];
  let date1:any = newDate.getDate();
      date1 = date1<10?'0'+date1:date1;
  let month:any = newDate.getMonth()+1;
      month =  month<10?'0'+month:month;
  return `${month}/${date1}`
}


_calculateAge(DOB) { // birthday is a date
  let birthday = new Date(DOB);
  console.log(birthday,"-----1")
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

_DOBvalidation(DOB) { // birthday is a date
  let birthday = new Date(DOB);
  console.log(birthday,"-----1")
  let today = new Date();
      today.setHours(0,0,0,0);
      if(birthday <= today){
        return true;
      }else{
       return false
      }
}

formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let second = date.getSeconds();
  var strTime = (hours>9?hours:'0'+hours) + ':' + (minutes>9?minutes:'0')+':'+ (second>0?second:'0'+second);
  return strTime;
}

arrayOfObjectSorting(arrayList:Array<any>, fieldName:string){
    return arrayList.sort((a, b) => {
    let fa = a[fieldName].toLowerCase(),
      fb = b[fieldName].toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
    });
}

}