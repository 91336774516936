import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardStateMaintainService {
  DashboardState: BehaviorSubject<any> = new BehaviorSubject({});

  constructor() {}
  getDashboardData() {
    return this.DashboardState;
  }
  setDashboarddata(updateDashboarddata: any) {
    this.DashboardState.next(updateDashboarddata);
  }
}
