import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { provideRoutes, Router } from "@angular/router";
import { GlobalService } from "@Globalservice";
import { TosterService } from "@toster";
import { environment } from "@env";
import { SocketService } from "../data-service/socket.io";
import { AuthServices } from "../Api-controller/auth.service";
import { cacheUrls, apiCache } from "./api.cache";
import { DashboardStateMaintainService } from "../../services/Common-service/dashboard-state-maintain.service";

@Injectable({ providedIn: "root" })
export class HttpConfigInterceptor implements HttpInterceptor {
  loader: boolean;
  sessionTime: any;
  selectedDepartmentInfo: any;
  constructor(
    private tosterService: TosterService,
    private authServices: AuthServices,
    private socketService: SocketService,
    private router: Router,
    private dashboardmaintainservice: DashboardStateMaintainService,

    public globalService: GlobalService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url.split(environment.API_BASE_PATH)[1];

    let checkCache = cacheUrls.includes(url);
    if (
      checkCache &&
      apiCache[url] &&
      +new Date() - apiCache[url].timestamp < 1000 * 60 * 30
    ) {
      this.globalService.loader = false;
      console.warn("Data cache found for url", apiCache[url].Data);
      const res: BehaviorSubject<any> = new BehaviorSubject(apiCache[url].Data);
      // const res:Observable<any> = new Observable(subscriber =>{ subscriber.next(apiCache[url].Data)})
      return res; // ;
    } else {
      const token: string = localStorage.getItem("access_token");
      const [authorization] = request.headers.keys();
      clearInterval(this.sessionTime);
      console.log(authorization);
      if (token) {
        request = request.clone({
          headers: request.headers.set("Authorization", token),
        });
        request = request.clone({
          headers: request.headers.set(
            "Username",
            localStorage.getItem("InstantUcInfo")
              ? `${JSON.parse(localStorage.getItem("InstantUcInfo")).Username}`
              : request.body.Username ?? ""
          ),
        });
      } else if (authorization) {
      } else {
        request = request.clone({
          headers: request.headers.set("Authorization", environment.API_KEY),
        });
      }

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (checkCache) {
            apiCache[url] = { Data: "", timestamp: "" };
            apiCache[url].Data = event;
            console.warn("recieved response", event);
            console.warn(
              "data will be inserted for ",
              url,
              " for the first time",
              apiCache[url]
            );
            console.warn(apiCache[url].Data);
            apiCache[url].timestamp = +new Date();
          }
          if (event instanceof HttpResponse) {
            setTimeout((res) => {
              this.globalService.loader = false;
            }, 300);
          }
          return event;
        }),

        catchError((error: HttpErrorResponse) => {
          setTimeout((res) => {
            this.globalService.loader = false;
          }, 300);
          if (error.status == 0) {
            this.tosterService.error("Please check Your Internet connection ");
          } else if (error.status == 403) {
            return throwError(error);
          } else if (error.status == 401) {
            this.router.navigateByUrl("/Auth");
          } else {
            console.log(error);
            // this.tosterService.error(error.error.ErrorDetails);
          }

          return throwError(error);
        })
      );
    }
  }

  expireToken() {
    let count = 0;
    clearInterval(this.sessionTime);
    this.sessionTime = setInterval((res) => {
      count++;

      if (count == 1800) {
        clearInterval(this.sessionTime);

        this.LogOut();
      }
    }, 1000);
  }

  LogOut() {
    this.selectedDepartmentInfo = JSON.parse(
      localStorage.getItem("In/s\tan/tUC%")
    );
    let logedInuserInfo = JSON.parse(localStorage.getItem("InstantUcInfo"));
    let data = {
      ClientIPAddress: logedInuserInfo.ClientIPAddress,
      LoggedInUserId: logedInuserInfo.UserId,
      Username: logedInuserInfo.Username,
    };
    this.authServices.LogOut(data).subscribe(
      (res) => {
        this.dashboardmaintainservice.setDashboarddata({});
        this.socketService.changeProviderStatus({
          status: "Logged Off",
          UserId: logedInuserInfo.UserId,
        });
        if (this.socketService.socket) {
          this.socketService.disconnect();
        }
        localStorage.clear();
        clearInterval(this.sessionTime);

        this.router.navigateByUrl("/Auth");
      },
      (error) => {
        if (error.status == 403) {
        }
      }
    );
  }
}
